/* @font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"), url(./assets/Segoe\ UI.ttf) format("truetype");
} */
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(./assets/fonts/HelveticaNeueLTStd-Th.otf) format("opentype");
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Helvetica";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.yellow-section {
  padding: "200px" !important;
}
